import React, {FC, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch} from '../../../hooks/global';
import {StringParam, useQueryParams} from "use-query-params";
import {APP_URI, SSO_URI} from '../../../constants/api';
import {fetchSsoLogin} from "../../../store/auth/authSlice";
import {Spinner} from "../../common";

const SignIn: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {userId} = useParams();

    const [query, setQuery] = useQueryParams({
        kcToken: StringParam,
        kcRefreshToken: StringParam,
    });
    const { kcToken, kcRefreshToken } = query;

    useEffect(() => {
        if (userId && kcToken && kcRefreshToken) {
            dispatch(fetchSsoLogin({kcToken, kcRefreshToken, userId}))
                .then(resp => {
                if (resp?.type === 'auth/login-sso/fulfilled') {
                    navigate('/sms-panel');
                }
            });
        } else {
            setTimeout(()=> {
                window.location.replace(`${SSO_URI}/login-sso?redirectUri=${APP_URI}/sign-in&platformId=5`);
            }, 2000);
        }
    }, [])

    return (
        <Spinner isLoading={true}/>
    );
};

export default SignIn;
