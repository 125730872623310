import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import moment from 'moment-timezone';
import {Controller, FormProvider, useFieldArray, useForm} from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {ROUTES} from "../../../router/routes";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchCaseTypes, fetchLocations} from "../../../store/general/generalSlice";
import {PageContent, PageContentContainer, Spinner} from "../../common";
import {BackButton} from "../../controls";
import Message from "./parts/Message";
import {
    fetchCampaignById,
    fetchCreateCampaign,
    fetchDefaultMessages,
    fetchHandleActive,
    fetchUpdateCampaign,
    resetCampaign
} from "../../../store/campaign/campaignSlice";
import {
    Form,
    PageGroup,
    Title,
    FormLabel,
    FormGroupRow,
    FormGroupCol,
    FormButton,
    BtnWrapper,
    FormGroup,
    FormInput,
    FormMultiSelect,
    FormSingleSelect,
    CampaignName,
    AddNewMessage,
    FormButtons,
    DraggableWrapper,
    FiltersWrapper,
    Legend,
    LegendHeader,
    LegendItem,
    LegendItems,
    HeaderWrapper,
    Titles,
    MessagesWrapper,
    StickyHeader,
    Divider,
    LegendItemsWrapper
} from "./styled";

const campaignTypes = [
    {value: 'default', label: 'Default J3Tech Campaign'},
    {value: 'custom', label: 'Custom Campaign'},
]

const emptyMessage = {
    message_id: null,
    campaign_id: null,
    title: '',
    text: '',
    cadence: 'date',
    after: '1',
    time: moment().tz('UTC').set({"hour": 12, "minute": 0}),
    date: moment().tz('UTC').set({"hour": 12, "minute": 0}),
    timezone: 'PST8PDT',
    position: 0,
    is_active: true,
}

const Campaign: FC = () => {
    moment.tz.setDefault("UTC");
    const navigate = useNavigate();
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const {campaign, defaultMessages, isLoading} = useAppSelector(state => state.campaign);
    const {locations, caseTypes, isLoading: isGeneralLoading} = useAppSelector(state => state.general);
    const {user, role} = useAppSelector(state => state.auth);
    const [locationOptions, setLocationOptions] = useState([{value: '', label: ''}]);
    const [caseTypesOptions, setCaseTypesOptions] = useState([{value: '', label: ''}]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLocationsDisabled, setIsLocationsDisabled] = useState(false);
    const methods = useForm<any>({mode: 'onBlur'});
    const {control, register, handleSubmit, reset, watch, setValue, formState: {errors}} = methods;
    const { fields, append, remove, move } = useFieldArray<any>({
        control,
        name: "messages",
    });

    const campaignType = watch('type');

    const getMessagesData = (messages: Array<any>, isDefaultMessages = false) => {
        return messages.map((item) => {
            return {
                title: item.title,
                is_active: !!item.is_active,
                text: item.text,
                cadence: item.cadence,
                after: item.after || '1',
                timezone: item.timezone || 'PST8PDT',
                position: item.position,
                message_id: isDefaultMessages ? null : (item.id || null),
                date: item.date ? moment(item.date) : moment(),
                time: item.time ? moment(`${moment().format('YYYY-MM-DD')} ${item.time}`) : null,
            }
        })
    }

    useEffect(() => {
        if ((role && role !== 'j3-company-admin') && id === undefined) {
            navigate(ROUTES.CAMPAIGNS, {state: {keepFilters: true}})
        }
    }, [role]);

    useEffect(() => {
        setIsDisabled(false);
        dispatch(fetchLocations());
        dispatch(fetchCaseTypes());
        dispatch(fetchDefaultMessages({}));
        dispatch(resetCampaign());
        if (id) {
            dispatch(fetchCampaignById(id));
        }
    }, [id]);

    useEffect(() => {
        if (locations) {
            setLocationOptions(locations.map((item) => ({
                value: item.location_id,
                label: item.name,
            })));
        }
    }, [locations]);

    useEffect(() => {
        if (caseTypes) {
            setCaseTypesOptions(caseTypes.map((item) => ({
                value: item.id,
                label: item.name,
            })));
        }
    }, [caseTypes]);

    useEffect(() => {
        if (campaignType) {
            remove();
            if (campaignType === 'default') {
                setValue('messages', getMessagesData(defaultMessages, true));
            } else {
                if (id) {
                    setValue('messages', getMessagesData(campaign?.messages || [{...emptyMessage, position: getLastPosition()}]));
                } else {
                    append({...emptyMessage, position: getLastPosition()});
                }
            }
        }
    }, [campaignType]);

    useEffect(() => {
        setIsDisabled(false);
        reset({
            id: campaign?.id || null,
            title: campaign?.title || '',
            type: campaign?.type || 'default',
            is_active: campaign?.is_active || 1,
            locations: campaign?.locations.map(item => item.location_id) || [],
            case_types: campaign?.types.map(item => item.id) || [],
            messages: getMessagesData(campaign?.messages || []),
        });

        if (user && campaign && campaign?.is_disabled) {
            if (campaign.is_disabled == 1) setIsLocationsDisabled(true);
            if (campaign.is_disabled == 2) setIsDisabled(true);
        }
    }, [campaign]);

    useEffect(() => {
        redefinePosition();
    }, [fields])

    useEffect(() => {
        if (defaultMessages && campaignType=== 'default' && !id) {
            setValue('messages', getMessagesData(defaultMessages, true));
        }
    }, [defaultMessages])

    const navToList = () => {
        navigate(ROUTES.CAMPAIGNS, {state: {keepFilters: true}})
    }

    const navToCampaign = (id: number) => {
        navigate(`/campaign/${id}`)
    }

    const handleOnSubmit = (data: any) => {
        console.log('handleOnSubmit data:', data);
        if (id) {
            dispatch(fetchUpdateCampaign({id, data}));
        } else dispatch(fetchCreateCampaign({data, cb: navToCampaign}));
    };

    const redefinePosition = () => {
        fields.forEach((item, idx) => {
            setValue(`messages.${idx}.position`, idx+1);
        })
    }

    const getLastPosition = () => {
        return fields.length + 1;
    }

    const handleNewMessage = () => {
        append({...emptyMessage, position: getLastPosition()});
    };

    const handleDeleteMessage = (idx: number) => {
        remove(idx);
    };

    const handleOnDragEnd = (result: any) => {
        const { source, destination} = result;
        if (!destination) {
            return;
        }
        move(source.index, destination.index);
    }

    const handleMakeActive = () => {
        dispatch(fetchHandleActive({id, data: {is_active: Number(!campaign?.is_active)}}));
    };

    const handleDragStart = (event: any) => {
        event.dataTransfer.setData('text', event.target.innerText);
    }
console.log('id', id);
    return (
        <PageContentContainer>
            <PageContent>
                <PageGroup>
                    <Spinner isLoading={isLoading || isGeneralLoading}/>
                    <Form>
                        <FormProvider {...methods}>
                            <StickyHeader>
                                <HeaderWrapper>
                                    <Titles>
                                        <Title>CAMPAIGN DETAILS</Title>
                                        <CampaignName>{campaign?.title || ''}</CampaignName>
                                    </Titles>
                                    <BtnWrapper>
                                        <BackButton height={36} onClick={navToList}/>
                                    </BtnWrapper>
                                </HeaderWrapper>
                                <FiltersWrapper>
                                    <FormGroup>
                                        <FormGroupRow>
                                            <FormGroupCol>
                                                <FormLabel>Campaign Name</FormLabel>
                                                <Controller
                                                    name={"title"}
                                                    control={control}
                                                    defaultValue={''}
                                                    rules={{required: true}}
                                                    render={({field: {ref, ...field}}) => (
                                                        <FormInput
                                                            inputSize={'xm'}
                                                            width={250}
                                                            disabled={isDisabled}
                                                            placeholder={'Campaign Name'}
                                                            error={errors.title}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </FormGroupCol>
                                            <FormGroupCol>
                                                <FormLabel>Locations</FormLabel>
                                                <Controller
                                                    name={"locations"}
                                                    control={control}
                                                    defaultValue={[]}
                                                    rules={{required: "This field is required"}}
                                                    render={({field: {ref, ...field}}) => (
                                                        <FormMultiSelect
                                                            {...field}
                                                            placeholder={'Select Location(s)'}
                                                            value={field.value}
                                                            options={locationOptions}
                                                            disabled={isDisabled || isLocationsDisabled}
                                                            error={errors.locations}
                                                        />
                                                    )}
                                                />
                                            </FormGroupCol>
                                        </FormGroupRow>
                                        <FormGroupRow>
                                            <FormGroupCol>
                                                <FormLabel>Campaign Type</FormLabel>
                                                <Controller
                                                    name={'type'}
                                                    control={control}
                                                    defaultValue={''}
                                                    rules={{required: "This field is required"}}
                                                    render={({field: {ref, ...field}}) => (
                                                        <FormSingleSelect
                                                            {...field}
                                                            options={campaignTypes}
                                                            value={field.value}
                                                            disabled={isDisabled}
                                                            placeholder={'Select One'}
                                                            error={errors.type}
                                                        />
                                                    )}
                                                />
                                            </FormGroupCol>
                                            <FormGroupCol>
                                                <FormLabel>Case Types</FormLabel>
                                                <Controller
                                                    name={"case_types"}
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({field: {ref, ...field}}) => (
                                                        <FormMultiSelect
                                                            {...field}
                                                            placeholder={'Select Case Type(s)'}
                                                            value={field.value}
                                                            options={caseTypesOptions}
                                                            disabled={isDisabled}
                                                            error={errors.case_types}
                                                        />
                                                    )}
                                                />
                                            </FormGroupCol>
                                        </FormGroupRow>
                                    </FormGroup>
                                    <Legend>
                                        <LegendHeader>Click to Drag & Drop Variable:</LegendHeader>
                                        <LegendItemsWrapper>
                                            <LegendItems>
                                                <LegendItem draggable={true} onDragStart={handleDragStart}>[COMPANY]</LegendItem>
                                                <LegendItem draggable={true} onDragStart={handleDragStart}>[LOCATION]</LegendItem>
                                                <LegendItem draggable={true} onDragStart={handleDragStart}>[DIRECTOR]</LegendItem>
                                                <LegendItem draggable={true} onDragStart={handleDragStart}>[CUSTOMER]</LegendItem>
                                                <LegendItem draggable={true} onDragStart={handleDragStart}>[DECEASED]</LegendItem>
                                            </LegendItems>
                                        </LegendItemsWrapper>
                                    </Legend>
                                </FiltersWrapper>
                                <Divider/>
                            </StickyHeader>
                            <MessagesWrapper>
                                <FormGroup>
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <DraggableWrapper
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {!!fields.length && fields.map((item: any, key) => (
                                                        <Draggable key={item.id} draggableId={item.id} index={key}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <Message
                                                                        isDisabled={isDisabled}
                                                                        provided={provided}
                                                                        snapshot={snapshot}
                                                                        register={register}
                                                                        key={item.id}
                                                                        idx={key}
                                                                        item={item}
                                                                        total={fields.length}
                                                                        deleteMessage={handleDeleteMessage}
                                                                        addNewMessage={handleNewMessage}
                                                                        dragHandleProps={provided.dragHandleProps}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </DraggableWrapper>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <AddNewMessage
                                        title={'+ ADD ANOTHER MESSAGE'}
                                        onClick={handleNewMessage}
                                        disabled={isDisabled}
                                        width={190}
                                        color={'yellow'}
                                    />
                                </FormGroup>
                            </MessagesWrapper>
                            <FormGroup>
                                <FormButtons>
                                    <FormButton
                                        title={id ? 'SAVE' : 'CREATE'}
                                        disabled={isDisabled}
                                        onClick={handleSubmit((data) => handleOnSubmit(data))}
                                        width={190}
                                    />
                                    {!!id && (
                                        <FormButton
                                            title={`MARK CAMPAIGN ${campaign?.is_active ? 'INACTIVE' : 'ACTIVE'}`}
                                            onClick={handleMakeActive}
                                            color={campaign?.is_active ? 'transparent' : 'yellow'}
                                            disabled={isDisabled}
                                            width={190}
                                        />
                                    )}
                                </FormButtons>
                            </FormGroup>
                        </FormProvider>
                    </Form>
                </PageGroup>
            </PageContent>
        </PageContentContainer>
    );
};

export default Campaign;
